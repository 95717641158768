import * as types from '../actions/ActionTypes';

const initialState = {};

const UserReducer = (state = initialState, action) => {
    if (types.REQUEST_LOGIN === action.type) {
        return {
            ...state,
            loginState: action.state,
            requestStatus: action.requestStatus
        };
    }

    if (types.LOGOUT === action.type) {
        return { loginState: action.state };
    }

    if (types.GET_USER_INFO === action.type) {
        return { ...state, userInfo: action.payload };
    }

    if (types.REQUEST_CHANGE_PASSWORD === action.type) {
        return { ...state, changePasswordStatus: action.state };
    }

    return state;
};

export default UserReducer;
