import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Add from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import CourseProgress from '../courses/CourseProgress';
import Grid from '@material-ui/core/Grid';
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto'
    },
    table: {
        minWidth: 700
    },
    addBtn: {
        height: 20,
        width: 20
    },
    tablerow: {
        backgroundColor: '#eee'
    }
});
class AllUsersColumns extends Component {
    render() {
        const { classes } = this.props;
        if (!this.props.users) {
            if (this.props.requestStatus === 'PROCESSING') return <div>Loading users</div>;
            else if (this.props.requestStatus === 'FAILURE') {
                return <div>Request Failed</div>;
            }
            return <div></div>;
        } else if (this.props.users.length === 0) {
            return <div>No users found</div>;
        } else {
            return (
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tablerow}>
                                <TableCell>Sr. No.</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <Grid container>
                                    <Grid xs item>
                                        <TableCell align="left">Courses</TableCell>
                                    </Grid>
                                    <Grid xs item>
                                        {' '}
                                        <TableCell align="left">Progress</TableCell>
                                    </Grid>
                                </Grid>
                                <TableCell align="left">Add Courses</TableCell>
                                <TableCell align="left">Role</TableCell>
                                <TableCell align="left">Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.users.map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">
                                            <CourseProgress courseStats={row.courseStats} />
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => this.props.history.push(`/user/${row.id}`)}>
                                                <Add className={classes.addBtn} />
                                                &nbsp;Add
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left">{row.role}</TableCell>
                                        <TableCell align="left">{row.createdAt}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }
}

AllUsersColumns.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AllUsersColumns);
