import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import MangerNavbar from './MangerNavbar';

class AllUsersHeader extends Component {
    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginTop: '1vh', marginLeft: '0vw', display: 'flex' }}>
                    <UserIcon style={{ height: '3vh', width: '4vw', margin: '1.5% 0 0 0' }} />
                    <Typography component="h2" style={{ color: 'green' }}>
                        <b>USERS</b>
                    </Typography>
                </div>
                <MangerNavbar
                    showAddUserContainer={this.props.showAddUserContainer}
                    makeManager={this.props.makeManager}
                    makeUser={this.props.makeUser}
                    setStartDate={this.props.setStartDate}
                    setEndDate={this.props.setEndDate}
                />
            </div>
        );
    }
}

export default AllUsersHeader;
