import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
        display: 'flex',
        marginBottom: '2%'
    },
    courseSelect: {
        width: '16vw',
        height: '2.3rem',
        margin: '1.7% 0 0 37%'
    },
    text: {
        fontSize: '1.2rem',
        marginRight: `${theme.spacing.unit * 71}px`
    },
    formControl: {
        margin: `${theme.spacing.unit * 1}px`,
        minWidth: 295
    }
});

class CourseSelector extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <p className={classes.text}>Select a course:</p>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Course</InputLabel>
                    <Select value={this.props.selectedCourseId} onChange={this.props.handleChangeCourse} name="courseId">
                        {this.props.courses.map(course => (
                            <MenuItem value={course.id} key={course.id}>
                                {course.name}
                            </MenuItem>
                        ))}
                        {this.props.selectedCourseId === -1 && <MenuItem>No live courses yet</MenuItem>}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(CourseSelector);
