import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    textFieldName: {
        border: 'none',
        borderBottom: `1px solid #888`,
        width: '16.5vw',
        height: '2rem',
        fontSize: '1.2rem',
        fontFamily: 'monserrat',
        textAlign: 'center',
        margin: '-1px 15.6vw 0% 1%',
        '&:focus': {
            outline: 'none',
            color: '#111'
        }
    },
    textFieldEmail: {
        border: 'none',
        borderBottom: `1px solid #888`,
        width: '16.5vw',
        height: '2rem',
        fontSize: '1.2rem',
        fontFamily: 'monserrat',
        textAlign: 'center',
        margin: '-1px 0% 0% 1%',
        '&:focus': {
            outline: 'none',
            color: '#111'
        }
    },
    text: {
        fontSize: '1.2rem',
        margin: '0',
        lineHeight: '2.2'
    }
});

class AddNameAndEmail extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <p className={classes.text}>Name:</p>
                <input
                    type="text"
                    className={classes.textFieldName}
                    placeholder="John"
                    name="name"
                    onChange={this.props.handleChangeName}
                    value={this.props.name}></input>
                <p className={classes.text}>Email:</p>
                <input
                    type="text"
                    className={classes.textFieldEmail}
                    placeholder="Doe"
                    name="email"
                    onChange={this.props.handleChangeEmail}
                    value={this.props.email}></input>
            </div>
        );
    }
}

export default withStyles(styles)(AddNameAndEmail);
