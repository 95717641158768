import React, { Component } from 'react';
import Drawer from './ManagerDrawer';
import Paper from '@material-ui/core/Paper';
import AllUsers from './AllUsers';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AddUser from './AddUser';
import { Transition, animated } from 'react-spring/renderprops';
import { Redirect } from 'react-router-dom';
import * as ManagerActions from '../actions/ManagerActions';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    paper: {
        width: '100%',
        height: '100vh',
        paddingTop: '1%'
    }
});

class ManagerHome extends Component {
    state = {
        showUserModal: false,
        manager: false,
        startdate: '',
        enddate: ''
    };

    componentDidMount() {
        if (this.props.requestedOrgId && !this.props.requestStatus) this.props.getAllUsersByOrganisation(this.props.requestedOrgId);
    }

    componentDidUpdate(_, prevState) {
        if (this.props.requestedOrgId && (this.state.startdate !== prevState.startdate || this.state.enddate !== prevState.enddate)) {
            this.props.getAllUsersByOrganisation(this.props.requestedOrgId, this.state.startdate, this.state.enddate);
        }
    }

    showUserModalState = () => this.setState({ showUserModal: true });
    hideUserModalState = () => this.setState({ showUserModal: false });

    makeManager = () => this.setState({ manager: true });
    makeUser = () => this.setState({ manager: false });
    setStartDate = date => {
        this.setState({ startdate: date });
    };
    setEndDate = date => {
        this.setState({ enddate: date });
    };
    render() {
        const { classes } = this.props;
        if (this.props.userRole) {
            if (this.props.userRole !== 'ADMIN' && this.props.userRole !== 'MANAGER') return <Redirect to="/NOT_FOUND" />;

            if (this.props.userRole === 'MANAGER' && this.props.requestedOrgId !== this.props.orgId) return <Redirect to="/NOT_FOUND" />;
        }
        return (
            <div>
                <div className={classes.root}>
                    <Drawer />
                    <Paper className={classes.paper}>
                        <Transition
                            native
                            items={this.state.showUserModal}
                            from={{
                                opacity: 0,
                                transform: 'translate3d(0,-100%,0)',
                                height: 0
                            }}
                            enter={{
                                opacity: 1,
                                transform: 'translate3d(0,0%,0)',
                                height: 'auto'
                            }}
                            leave={{
                                opacity: 0,
                                transform: 'translate3d(0,-100%,0)',
                                height: 0
                            }}>
                            {show =>
                                show &&
                                (props => (
                                    <animated.div style={props}>
                                        <AddUser
                                            hideAddUserContainer={this.hideUserModalState}
                                            orgId={this.props.requestedOrgId}
                                            manager={this.state.manager}
                                        />
                                    </animated.div>
                                ))
                            }
                        </Transition>
                        <div>
                            <AllUsers
                                users={this.props.users}
                                requestStatus={this.props.requestStatus}
                                showAddUserContainer={this.showUserModalState}
                                history={this.props.history}
                                makeManager={this.makeManager}
                                makeUser={this.makeUser}
                                setStartDate={this.setStartDate}
                                setEndDate={this.setEndDate}
                            />
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const userInfo = state.users.userInfo ? state.users.userInfo : null;
    const orgId = userInfo ? userInfo.organisationId : null;
    const requestedOrgId = ownProps.match.params.id ? parseInt(ownProps.match.params.id) : orgId;
    const users = state.manager.users && state.manager.users.data ? state.manager.users.data[requestedOrgId] : undefined;
    return {
        orgId,
        users: users,
        requestStatus: state.manager.users && state.manager.users.status ? state.manager.users.status[requestedOrgId] : undefined,
        userRole: userInfo ? userInfo.role : undefined,
        requestedOrgId
    };
};
const mapDispatchToProps = dispatch => ({
    getAllUsersByOrganisation: (id, startdate, enddate) => dispatch(ManagerActions.getAllUsersByOrganisationID(id, startdate, enddate))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManagerHome));
