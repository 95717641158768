import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
    loader: {
        color: '#5eaa46'
    }
});
class Loader extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
                <CircularProgress className={classes.loader} size={60} />
                <p>
                    <b>Processing Request</b>
                </p>
            </div>
        );
    }
}
export default withStyles(styles)(Loader);
