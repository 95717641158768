/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import CourseSelector from './CourseSelector';
import DefaultPasswordSelector from './DefaultPasswordSelector';
import {withStyles} from '@material-ui/core/styles';
import AddNameAndEmail from './AddNameAndEmail';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import {connect} from 'react-redux';
import * as ManagerActions from '../actions/ManagerActions';
import * as snackBarActions from '../actions/SnackbarActions';


const styles =  theme => ({
    root:{
        border: 'solid 2px #2e6d4a',
        borderRadius: '5px',
        height:'21rem',
        '@media(min-width:1800px)':{
            height:'23rem',                                
        },
        '@media(min-width:2400px)':{
            height:'28rem',                                
        },
        padding: '1vh 4vh 4vh 4vh',
        boxSizing: 'border-box',
    },
    passwordContainer:{
        margin: '0 0 1% 0'
    },
    submitButton : {
        margin:'0',
        padding:'0',
        width: '320px',
        borderRadius:'30px',
        fontWeight:'900',
        fontSize:'23px',
        color:'green',
        '&:hover':{
            color: '#fff',
            backgroundColor: '#2e6d4a'
        }
    },
    close:{
        marginLeft:'99.7%',
        border: 'none',
        cursor:'pointer',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    userInfoContainer:{
        display:'flex', 
        width:'100%'
    },
    buttonContainer:{
        marginTop:'1rem',
        textAlign:'center',
    },
    checkboxWrapper:{
        marginLeft:'8vw'
    },
    note:{
        textAlign:'center',
        marginTop:'3rem'
    }
});


class AddUser extends Component {
    state = {
        name: "",
        email:"",
        defaultPassword: "",
        courseId:-1,
        showUserModal:true,
    }
    handleChange = (e) => {
        const {name, value} =  e.target;
        this.setState({[name]: value});
    }

    handleAddNewUserRequest= () =>{
        const role = this.props.manager ? "MANAGER" : "USER";
        const courseId  = role==="USER" ? this.state.courseId : null;
        this.props.addNewUser(this.props.orgId,this.state.name.trim(), this.state.email.trim(), this.state.defaultPassword, courseId, role)
        .then(requestStatus => {
            if(requestStatus !== undefined){
                this.props.snackBar("New user added successfully!");
                this.props.getAllUsersByOrganisation(this.props.orgId);
                this.setState({name:"",email:"",manager:false});
            }
        });
    }

    componentDidMount = () => this.props.getAllLiveCourses()


    componentDidUpdate(){
        if(this.state.courseId===-1 && typeof this.props.defaultCourseId === "number") this.setState({courseId: this.props.defaultCourseId});
    }
    
    render() {
        const {classes} = this.props;
        return (
                    <div className={classes.root}>
                        <button className={classes.close}><CloseIcon onClick={this.props.hideAddUserContainer}/></button>
                        <div className={classes.passwordContainer}>
                        {!this.props.manager && <CourseSelector handleChangeCourse={this.handleChange} selectedCourseId={this.state.courseId} courses={this.props.liveCourses || []}/>} 
                        <DefaultPasswordSelector handleChangePassword={this.handleChange} 
                        password={this.state.password}/>                
                        </div>
                        <div className={classes.userInfoContainer}>
                            <AddNameAndEmail handleChangeName={this.handleChange} name={this.state.name}
                            handleChangeEmail={this.handleChange} 
                            email={this.state.email}/>
                        </div>
                        {this.props.manager && <div className={classes.note}><b>Note : </b>Making user manager will grant this user all the privileges that you currently have.</div>}
                        <div className={classes.buttonContainer}>
                            <Button variant='outlined' className={classes.submitButton} onClick={this.handleAddNewUserRequest}>Add</Button>
                        </div>
                    </div>
        );
    }
}

const mapStateToProps = (state) => {
    const liveCourses =  state.manager.liveCourses ? state.manager.liveCourses : [];
    return {
        liveCourses : state.manager.liveCourses,
        defaultCourseId: liveCourses.length ? state.manager.liveCourses[0].id : undefined
    };
};
const mapDispatchToProps = dispatch => ({
    getAllUsersByOrganisation : (id) => dispatch(ManagerActions.getAllUsersByOrganisationID(id)),
    addNewUser: (orgId, name, email, password, courseId, role) => dispatch(ManagerActions.addNewUser(orgId,name, email, password, courseId, role)),
    getAllLiveCourses: () => dispatch(ManagerActions.getAllLiveCourses()),
    snackBar : (msg) => dispatch(snackBarActions.setErrorState(msg))
});

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddUser));