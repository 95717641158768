import React, { Component } from 'react';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../components/Loader';
import SubscriptionRequestLandingPage from '../components/SubscriptionRequestLandingPage';
const styles = theme => ({
    root: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        'text-align': 'center',
        'min-height': '100vh'
    }
});
class ApproveCourseSubscription extends Component {
    state = {
        status: '',
        responseStatus: '',
        token: '',
        userId: '',
        showErrorMsg: false,
        requestedUser: '',
        subrequestId: '',
        expirationDate: '',
        courseName: '',
        email: '',
        loading: true
    };

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        this.setState({
            token: query.token,
            status: query.status,
            email: query.email,
            courseName: query.courseName
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token) {
            fetch(`/server/subscriptions/subscription-request`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: this.state.token,
                    status: this.state.status
                })
            }).then(response => {
                if (response.status !== 200) {
                    this.setState({ responseStatus: 'Invalid' });
                } else {
                    if (this.state.status === 'Approved') {
                        this.setState({ responseStatus: 'Approved' });
                    } else {
                        this.setState({ responseStatus: 'Rejected' });
                    }
                }
                this.setState({ loading: false });
            });
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.state.loading ? <Loader /> : <SubscriptionRequestLandingPage status={this.state.responseStatus} />}
            </div>
        );
    }
}
export default withStyles(styles)(ApproveCourseSubscription);
