import * as types from '../actions/ActionTypes';

const initialState = {
    users: {
        data: {},
        status: {}
    }
};

const ManagerReducer = (state = initialState, action) => {
    if (action.type === types.FETCH_USERS_BY_ORGANISATION) {
        const x = {
            ...state,
            users: {
                data: {
                    ...state.users.data,
                    [action.organisationId]: action.payload
                },
                status: {
                    ...state.users.status,
                    [action.organisationId]: action.status
                }
            }
        };
        return x;
    }

    if (action.type === types.FETCH_ALL_LIVE_COURSES) {
        return {
            ...state,
            liveCourses: action.payload
        };
    }

    return state;
};

export default ManagerReducer;
