import * as types from './ActionTypes';
import * as snackBarActions from './SnackbarActions';

export const login = (username, password) => {
    return dispatch => {
        dispatch(requestLogin(types.STATE_PROCESSING));
        const requestBody = 'username=' + username + '&password=' + password;
        return fetch('/server/login', {
            body: requestBody,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
            method: 'post'
        })
            .then(handleErrors)
            .then(response => dispatch(requestLogin(types.STATE_SUCCESS, response.status)))
            .catch(error => dispatch(requestLogin(types.STATE_FAILURE, JSON.parse(error.message))));
    };
};

export const requestLogin = (status, requestStatus) => ({
    type: types.REQUEST_LOGIN,
    state: status,
    requestStatus
});

export const requestChangePassword = state => ({
    type: types.REQUEST_CHANGE_PASSWORD,
    state: state
});

export const clearLoginState = () => ({
    type: types.LOGOUT,
    state: types.LOGGED_OUT
});

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.status || '');
    }
    return response;
}

export const checkLogin = () => {
    return dispatch => {
        fetch('/server/loggedin')
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.loggedin === true) dispatch(requestLogin(types.STATE_SUCCESS));
                else dispatch(requestLogin(types.LOGGED_OUT));
                dispatch(loggedinResponse(responseJSON.links));
            });
    };
};

const loggedinResponse = links => ({
    type: types.CHECK_LOGGEDIN,
    payload: links
});

export const logout = () => {
    return dispatch => {
        fetch('/server/logout')
            .then(handleErrors)
            .then(response => {
                dispatch(clearLoginState());
            })
            .catch(() => {
                dispatch(snackBarActions.setErrorState('Error logging out. Please try again later.'));
            });
    };
};

export const changePassword = password => {
    return dispatch => {
        dispatch(requestChangePassword(types.STATE_PROCESSING));
        return fetch('/server/users/me/password', {
            body: JSON.stringify({ password: password }),
            headers: { 'Content-Type': 'application/json;' },
            method: 'put'
        })
            .then(response => handleErrors(response))
            .then(response => {
                dispatch(requestChangePassword(types.STATE_SUCCESS));
            })
            .catch(error => {
                dispatch(requestChangePassword(types.STATE_FAILURE));
            });
    };
};

export const clearChangePassword = () => requestChangePassword();

export const getUserInfo = () => dispatch => {
    fetch('/server/users/me')
        .then(handleErrors)
        .then(response => response.text())
        .then(response => JSON.parse(response))
        .then(response => {
            dispatch({
                type: types.GET_USER_INFO,
                payload: response
            });
        })
        .catch(() => {
            dispatch(snackBarActions.setErrorState('Something went wrong. Try refreshing the page.'));
        });
};

export const subscribeUserToCourse = (userId, courseId) => {
    return dispatch => {
        return fetch(`/server/subscriptions/subscribebyuserId`, {
            body: JSON.stringify({ userId, courseId }),
            headers: { 'Content-Type': 'application/json;' },
            method: 'post'
        }).catch(() => dispatch(snackBarActions.setErrorState('Something went wrong. Try refreshing the page.')));
    };
};
