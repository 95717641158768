import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    courseSelect: {
        width: '25rem',
        height: '5rem',
        margin: '0 0 0 3rem'
    },
    password: {
        border: 'none',
        borderBottom: '1px solid #888',
        width: '16vw',
        textAlign: 'center',
        fontSize: '1rem',
        margin: '-1px 0% 3% 34.3%',
        '&:focus': {
            outline: 'none',
            color: '#111'
        }
    },
    text: {
        fontSize: '1.2rem',
        margin: '0'
    }
});

class DefaultPasswordSelector extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <p className={classes.text}>Type a default password:</p>
                <input
                    type="password"
                    className={classes.password}
                    placeholder="Password"
                    value={this.props.password}
                    name="defaultPassword"
                    onChange={this.props.handleChangePassword}></input>
            </div>
        );
    }
}

export default withStyles(styles)(DefaultPasswordSelector);
