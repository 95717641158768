import React, { Component } from 'react';
import CourseCard from './CourseCard';
import Drawer from '../manager/ManagerDrawer';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as ManagerActions from '../actions/ManagerActions';

const styles = theme => ({
    root: {
        display: 'flex',
        padding: '1%',
        fontFamily: 'Arial',
        color: '#444'
    },
    cardContainer: {
        display: 'flex'
    },
    userInfo: {
        padding: '1em',
        width: '100%',
        marginBottom: '4em'
    },
    content: {
        width: '100%'
    },
    progressContainer: {
        width: '100%',
        backgroundColor: `${theme.palette.primary.main}66`,
        marginTop: '3em',
        height: '2px'
    },
    subHeader: {
        marginTop: '2em'
    }
});

class CourseSubscribe extends Component {
    componentDidMount() {
        this.props.getAllLiveCourses();
    }
    render() {
        const { classes } = this.props;
        const user = this.props.user ? this.props.user : {};
        const courses = user.courseStats ? user.courseStats.map(course => course.id) : [];
        return (
            <div className={classes.root}>
                <Drawer />
                <div className={classes.content}>
                    <div className={classes.userInfo}>
                        {user.name && (
                            <h1>
                                {user.name} ({user.email})
                            </h1>
                        )}
                        <div className={classes.progressContainer}></div>
                    </div>
                    <div className={classes.cardContainer}>
                        {this.props.courses &&
                            this.props.courses.map(course => (
                                <CourseCard
                                    key={course.id}
                                    id={course.id}
                                    courseName={course.name}
                                    userId={user.id}
                                    courseId={course.id}
                                    gradient={'linear-gradient(#1FA2FF,#A6FFCB)'}
                                    courseDesc={course.description}
                                    orgId={this.props.orgId}
                                    subscribed={courses.includes(course.id)}
                                />
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const userInfo = state.users.userInfo ? state.users.userInfo : {};
    const orgId = userInfo ? userInfo.organisationId : null;
    const users = state.manager.users.data && orgId ? state.manager.users.data[orgId] : [];
    const user = users ? users.filter(user => user.id === parseInt(ownProps.match.params.id)) : {};
    return {
        courses: state.manager.liveCourses,
        user: user ? user[0] : user,
        orgId
    };
};

export const mapDispatchToProps = dispatch => ({
    getAllLiveCourses: () => dispatch(ManagerActions.getAllLiveCourses())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CourseSubscribe));
