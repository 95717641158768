import * as snackBarActions from './SnackbarActions';
import * as UserActions from './UserActions';

export function handleErrors(response) {
    if ((response.status !== 200) & (response.status !== undefined)) {
        throw response;
    }
    return response;
}
export function handleErrorResponseCodes(code, message, dispatch) {
    if (code === 401) {
        dispatch(UserActions.logout());
    } else if (code === 403 || code === 404 || code === 402 || code === 409 || code === 400) {
        if (message === null || message === undefined) {
            dispatch(snackBarActions.setErrorState('Something went wrong. Please try again later.'));
        } else {
            dispatch(snackBarActions.setErrorState(message));
        }
    } else if (!code) {
        dispatch(snackBarActions.setErrorState('Check your internet connectivity and retry.'));
    } else {
        dispatch(snackBarActions.setErrorState('Something went wrong. Try refreshing the page.'));
    }
}
