import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import EventReducer from './EventReducer';
import CourseReducer from './CourseReducer';
import ManagerReducer from './ManagerReducer';
import LinkReducer from './LinkReducer';

export default combineReducers({
    users: UserReducer,
    events: EventReducer,
    courses: CourseReducer,
    manager: ManagerReducer,
    links: LinkReducer
});
