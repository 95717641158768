import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    courseColumn: {
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '14px'
    },
    progressbar: {
        width: '80%'
    },
    progressBarWrapper: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    progressbarstatus: {
        fontSize: '13.5px'
    },
    colorPrimary: {
        backgroundColor: '#f2a899'
    },
    barColorPrimary: {
        backgroundColor: '#f73a14'
    },
    colorPrimary1: {
        backgroundColor: '#fcf590'
    },
    barColorPrimary1: {
        backgroundColor: '#e6d707'
    },
    colorPrimary2: {
        backgroundColor: '#b5f7cf'
    },
    barColorPrimary2: {
        backgroundColor: '#18944b'
    },
    colorPrimary3: {
        backgroundColor: '#b8b8b8'
    },
    barColorPrimary3: {
        backgroundColor: '#b8b8b8'
    }
});

const CourseProgress = props => {
    const classes = useStyles(props);
    return (
        <>
            {props.courseStats.map((course, index) => {
                const progressValue = Math.round((course.answers / course.questions) * 100);
                const className =
                    course.questions === 0
                        ? {
                              colorPrimary: classes.colorPrimary3,
                              barColorPrimary: classes.barColorPrimary3
                          }
                        : progressValue < 25
                        ? {
                              colorPrimary: classes.colorPrimary,
                              barColorPrimary: classes.barColorPrimary
                          }
                        : (progressValue > 25) & (progressValue < 50)
                        ? {
                              colorPrimary: classes.colorPrimary1,
                              barColorPrimary: classes.barColorPrimary1
                          }
                        : {
                              colorPrimary: classes.colorPrimary2,
                              barColorPrimary: classes.barColorPrimary2
                          };
                const value = course.questions === 0 ? 0 : progressValue;
                const title = course.questions === 0 ? 'progress not available' : progressValue + '%';
                return (
                    <Grid spacing={1} container className={classes.courseColumn} key={index}>
                        <Grid xs item>
                            {course.name}
                        </Grid>
                        <Grid xs item>
                            <LinearProgress
                                variant="determinate"
                                value={value}
                                className={classes.progressbar}
                                title={title}
                                classes={className}
                            />
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default CourseProgress;
