import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Subscribed from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import * as userActions from '../actions/UserActions';
import * as ManagerActions from '../actions/ManagerActions';
import SimpleModal from './SimpleModal';

const styles = theme => ({
    root: {
        color: '#000',
        width: '340px',
        '@media(max-width:500px)': {
            width: '300px'
        },
        boxSizing: 'border-box',
        '@media(max-width:1200px)': {
            margin: '2em 0'
        },
        margin: '0 1em',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '5px 5px 10px #ccc'
    },
    courseImage: {
        height: '180px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '25px'
    },
    courseDesc: {
        padding: '1em',
        color: '#555',
        fontSize: '15px',
        height: '280px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#fffffe',
        borderRadius: '0 0 8px 8px',
        boxShadow: 'inset 0px 0px 2px #ccc'
    },
    subscribe: {
        fontSize: '16px',
        borderRadius: '30px',
        width: '60%',
        border: 'none',
        color: '#fff',
        backgroundColor: '#56a58a',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#2e6d4a'
        }
    },
    descText: {
        margin: '0',
        height: '150px'
    },
    subscribed: {
        color: '#56a58a',
        height: '38px',
        width: '38px'
    },
    htmlButton: {
        border: 'none',
        backgroundColor: '#0000',
        color: 'blue'
    },
    descAndButton: {
        display: 'fle'
    }
});

class CourseCard extends Component {
    state = {
        open: false
    };

    componentDidMount() {
        if (this.props.courseDesc.length >= 200)
            document.getElementById(`readMoreButton${this.props.id}`).addEventListener('click', this.handleOpen);
    }

    componentWillUnmount() {
        if (this.props.courseDesc.length >= 200)
            document.getElementById(`readMoreButton${this.props.id}`).removeEventListener('click', this.handleOpen);
    }

    handleOpen = () => this.setState({ open: true });

    handleClose = () => this.setState({ open: false });

    subscribeAndFetchUpdatedDetails = () => {
        this.props
            .subscribeUserToCourse(this.props.userId, this.props.courseId)
            .then(response => this.props.getAllUsersByOrganisation(this.props.orgId));
    };
    render() {
        const { classes } = this.props;
        const readMore =
            this.props.courseDesc.length < 200
                ? ''
                : `...<button class=${classes.htmlButton} id=readMoreButton${this.props.id}>read more</button>`;
        return (
            <div className={classes.root}>
                <div className={classes.courseImage} style={{ backgroundImage: `${this.props.gradient}` }}>
                    {this.props.courseName}
                </div>
                <div className={classes.courseDesc}>
                    <div className={classes.descAndButton}>
                        <p
                            className={classes.descText}
                            dangerouslySetInnerHTML={{
                                __html: `${this.props.courseDesc.substring(0, 200)} ${readMore}`
                            }}
                        />
                    </div>
                    {!this.props.subscribed ? (
                        <Button className={classes.subscribe} variant="outlined" onClick={this.subscribeAndFetchUpdatedDetails}>
                            Subscribe
                        </Button>
                    ) : (
                        <Subscribed className={classes.subscribed} />
                    )}
                </div>
                <SimpleModal
                    open={this.state.open}
                    handleClose={this.handleClose}
                    handleOpen={this.handleOpen}
                    title={this.props.courseName}
                    desc={this.props.courseDesc}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    subscribeUserToCourse: (userId, courseId) => dispatch(userActions.subscribeUserToCourse(userId, courseId)),
    getAllUsersByOrganisation: id => dispatch(ManagerActions.getAllUsersByOrganisationID(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CourseCard));
