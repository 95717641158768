import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/PersonAdd';
import TextField from '@material-ui/core/TextField';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../formatDate';
const styles = theme => ({
    button: {
        color: 'green',
        padding: '0 1vh 0 1vh',
        margin: '1vh 3vh 0 0',
        fontSize: '1.2rem',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#2e6d4a'
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 150
    },
    typography: {
        padding: theme.spacing(2)
    },
    reset: {
        marginLeft: 130
    }
});

class MangerNavbar extends Component {
    state = {
        datefilter: false,
        startdate: '2010-01-01',
        enddate: new Date().toISOString().substring(0, 10)
    };
    handleAddUser = () => {
        this.props.makeUser();
        this.props.showAddUserContainer();
    };

    handleAddManager = () => {
        this.props.makeManager();
        this.props.showAddUserContainer();
    };
    OnStartDateChange = e => {
        var date = e.target.value;
        this.setState({ startdate: e.target.value });
        this.props.setStartDate(formatDate(date));
    };
    OnEndDateChange = e => {
        var date = e.target.value;
        this.setState({ enddate: e.target.value });
        this.props.setEndDate(formatDate(date));
    };
    handleResetClick = () => {
        this.props.setStartDate('2010-01-01');
        this.props.setEndDate(formatDate(new Date().toISOString().substring(0, 10)));
        this.setState({ startdate: '2010-01-01', enddate: new Date().toISOString().substring(0, 10) });
    };
    handleDateFilterClick = () => this.setState({ datefilter: !this.state.datefilter });
    render() {
        const { classes } = this.props;
        return (
            <nav>
                <Popover
                    open={this.state.datefilter}
                    id="dateFilter"
                    anchorReference="anchorPosition"
                    anchorPosition={{ left: 390 }}
                    onClose={this.handleDateFilterClick}>
                    <Typography className={classes.typography}>
                        <form className={classes.container} noValidate id="date-range">
                            <TextField
                                id="date"
                                label="Start Date"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={this.OnStartDateChange}
                                value={this.state.startdate}
                            />
                            <TextField
                                id="date"
                                label="End Date"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={this.OnEndDateChange}
                                value={this.state.enddate}
                            />
                        </form>
                        <Button variant="outlined" className={[classes.button, classes.reset]} onClick={this.handleResetClick}>
                            Reset
                        </Button>
                    </Typography>
                </Popover>
                <Button variant="outlined" className={classes.button} aria-describedby="dateFilter" onClick={this.handleDateFilterClick}>
                    Filter
                    <FilterListIcon />{' '}
                </Button>

                <Button variant="outlined" className={classes.button} onClick={this.handleAddUser}>
                    Add Users
                    <AddUserIcon />
                </Button>
                <Button variant="outlined" className={classes.button} onClick={this.handleAddManager}>
                    Add Managers
                    <AddUserIcon />
                </Button>
            </nav>
        );
    }
}

export default withStyles(styles)(MangerNavbar);
