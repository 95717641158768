import * as types from './ActionTypes';
import { handleErrors, handleErrorResponseCodes } from './ResponseErrorHandler';
import { formatDate } from '../formatDate';

export const getAllUsersByOrganisationID = (id, startdate, enddate) => {
    if (startdate === undefined || startdate === '') {
        startdate = new Date(2010, 1, 1).toISOString().substring(0, 10);
        startdate = formatDate(startdate);
    }
    if (enddate === undefined || enddate === '') {
        enddate = new Date().toISOString().substring(0, 10);
        enddate = formatDate(enddate);
    }
    return dispatch => {
        dispatch(organisationUsersRequest(id, types.STATE_PROCESSING));
        return fetch(`/server/manage/organisations/${id}/users/?startdate=${startdate}&enddate=${enddate}`)
            .then(handleErrors)
            .then(response => response.text())
            .then(textResponse => JSON.parse(textResponse))
            .then(jsonResponse => dispatch(organisationUsersRequest(id, types.STATE_SUCCESS, jsonResponse)))
            .catch(error => {
                handleErrorResponseCodes(JSON.parse(error.message), dispatch);
                dispatch(organisationUsersRequest(id, types.STATE_FAILURE));
            });
    };
};

export const addNewUser = (orgId, name, email, password, courseId, role) => {
    return dispatch => {
        const requestBody = {
            name: name,
            email: email,
            password: password,
            courseId: courseId,
            role: role
        };
        return fetch(`/server/manage/organisations/${orgId}/users`, {
            body: JSON.stringify(requestBody),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST'
        })
            .then(response => response.json())
            .then(handleErrors)
            .catch(error => {
                if (error.message === 'Failed to fetch') {
                    handleErrorResponseCodes('NET_DISCONNECTED', dispatch);
                }
                if (error.status === 409 || error.status === 402) {
                    handleErrorResponseCodes(error.status, error.message, dispatch);
                } else {
                    handleErrorResponseCodes(error.status, error.errors[0].defaultMessage, dispatch);
                }
            });
    };
};

export const getAllLiveCourses = () => {
    return dispatch => {
        return fetch('/server/courses/live')
            .then(handleErrors)
            .then(response => response.text())
            .then(textResponse => JSON.parse(textResponse))
            .then(jsonResponse => dispatch({ type: types.FETCH_ALL_LIVE_COURSES, payload: jsonResponse }))
            .catch(error => {
                handleErrorResponseCodes(JSON.parse(error.message), dispatch);
            });
    };
};

export const organisationUsersRequest = (organisationId, status, payload) => ({
    type: types.FETCH_USERS_BY_ORGANISATION,
    organisationId: organisationId,
    status: status,
    payload: payload
});
