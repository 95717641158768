import * as types from '../actions/ActionTypes';

const initialState = { error: { state: false, message: '' } };

const EventReducer = (state = initialState, action) => {
    if (types.ERROR === action.type) {
        return { ...state, error: action.payload };
    }

    return state;
};

export default EventReducer;
