import { Divider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as ManagerActions from '../actions/ManagerActions';
import * as userActions from '../actions/UserActions';
import ChangePasswordModal from '../components/ChangePasswordModal';
import Statistics from './Statistics';
import UserInfo from './UserInfo';
import UserOptions from './UserOptions';

const drawerWidth = '18vw';

const styles = theme => ({
    root: {
        display: 'flex',
        fontFamily: 'Arial',
        color: '#fff',
        fontSize: '18px'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#2e6d4a'
    },
    logo: {
        height: '0.45in'
    },
    divider: {
        backgroundColor: '#fff'
    },
    drawerBody: {
        padding: '1vw'
    },
    logoContainer: {
        backgroundColor: '#1a3f2a',
        textAlign: 'center',
        padding: '0.7vh',
        marginBottom: '4vh'
    }
});

class ManagerDrawer extends Component {
    state = {
        openChangePasswordModal: false
    };

    componentDidMount() {
        this.props.getUserInfo();
        if (this.props.orgId) this.props.getAllUsersByOrganisation(this.props.orgId);
    }

    componentDidUpdate() {
        if (this.props.orgId && !this.props.requestStatus) this.props.getAllUsersByOrganisation(this.props.orgId);
    }

    toggleOpenChangePasswordModal = () => {
        this.setState({ openChangePasswordModal: true });
    };

    onChangePasswordModalClose = () => {
        this.setState({ openChangePasswordModal: false });
    };

    countUsers = () =>
        this.props.users.filter(user => {
            return user.role === 'USER';
        }).length;

    countManagers = () =>
        this.props.users.filter(user => {
            return user.role === 'MANAGER';
        }).length;

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    anchor="left">
                    <div className={classes.logoContainer}>
                        <Link to="/">
                            <img className={classes.logo} src="/static/images/interleaplogo.png" alt="Company-Logo" />
                        </Link>
                    </div>

                    <div className={classes.drawerBody}>
                        <UserInfo username={this.props.username} organisationName={this.props.organisationName} />
                        <Divider className={classes.divider} />
                        <Statistics
                            totUsers={this.props.users ? this.countUsers() : 0}
                            totManagers={this.props.users ? this.countManagers() : 0}
                        />
                        <Divider className={classes.divider} />
                    </div>
                    <UserOptions logout={this.props.logout} toggleOpenChangePasswordModal={this.toggleOpenChangePasswordModal} />
                </Drawer>
                <ChangePasswordModal open={this.state.openChangePasswordModal} handleClose={this.onChangePasswordModalClose} />
            </div>
        );
    }
}
export const mapStateToProps = state => {
    const userInfo = state.users.userInfo ? state.users.userInfo : {};
    const orgId = userInfo ? userInfo.organisationId : null;
    const users = state.manager.users && state.manager.users.data ? state.manager.users.data[orgId] : undefined;
    return {
        username: userInfo.name,
        orgId,
        organisationName: userInfo.organisationName,
        users: users,
        requestStatus: state.manager.users && state.manager.users.status ? state.manager.users.status[orgId] : undefined,
        userRole: userInfo ? userInfo.role : undefined
    };
};

export const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(userActions.logout()),
    getAllUsersByOrganisation: id => dispatch(ManagerActions.getAllUsersByOrganisationID(id)),
    getUserInfo: () => dispatch(userActions.getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManagerDrawer));
