import React, { Component } from 'react';
import AllUsersHeader from './AllUsersHeader';
import AllUsersColumns from './AllUsersColumns';

class AllUsers extends Component {
    render() {
        return (
            <div>
                <AllUsersHeader
                    showAddUserContainer={this.props.showAddUserContainer}
                    makeManager={this.props.makeManager}
                    makeUser={this.props.makeUser}
                    setStartDate={this.props.setStartDate}
                    setEndDate={this.props.setEndDate}
                />
                <AllUsersColumns users={this.props.users} requestStatus={this.props.requestStatus} history={this.props.history} />
            </div>
        );
    }
}

export default AllUsers;
