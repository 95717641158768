import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    clientInfo: {
        '@media(min-width:2400px)': {
            margin: '0 0 5vh 0'
        }
    },
    icon: {
        margin: '5% 6% 0 0',
        height: '0.3in'
    },
    userInfoText: {
        color: 'white'
    }
});

const UserInfo = props => {
    const { classes } = props;
    return (
        <div className={classes.clientInfo}>
            <div style={{ display: 'flex', color: 'white' }}>
                <img src="/static/images/user-4-512.png" alt="stats" className={classes.icon} />
                <p className={classes.userInfoText}>
                    <b>Manager</b>
                </p>
            </div>
            <p className={classes.userInfoText}>Hello! {props.username}</p>
            <p className={classes.userInfoText}>{props.organisationName}</p>
        </div>
    );
};

export default withStyles(styles)(UserInfo);
