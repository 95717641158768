import * as types from './ActionTypes';

export const setErrorState = message => ({
    type: types.ERROR,
    payload: { state: true, message: message }
});

export const resetErrorState = () => ({
    type: types.ERROR,
    payload: { state: false, message: '' }
});
