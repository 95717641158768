import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    statsContainer: {
        padding: '8vh 0 8vh 0',
        '@media(min-width:1800px)': {
            padding: '16vh 0 16vh 0'
        },
        '@media(min-width:2400px)': {
            padding: '20vh 0 20vh 0'
        }
    },
    icon: {
        height: '0.3in'
    },
    iconText: {
        lineHeight: '0.5',
        marginLeft: '6%',
        color: 'white'
    },
    header: {
        display: 'flex'
    },
    infoText: {
        color: 'white'
    }
});

const Statistics = props => {
    const { classes } = props;
    return (
        <div className={classes.statsContainer}>
            <div className={classes.header}>
                <img src="/static/images/combo-512.png" alt="stats" className={classes.icon} />
                <p className={classes.iconText}>
                    <b>Statistics</b>
                </p>
            </div>
            <p className={classes.infoText}>Total no of users: {props.totUsers}</p>
            <p className={classes.infoText}>Total no of managers: {props.totManagers}</p>
        </div>
    );
};

export default withStyles(styles)(Statistics);
