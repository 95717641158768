import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
const styles = theme => ({
    tickIcon: {
        height: '150px',
        width: '150px',
        color: '#5eaa46'
    },
    cancelIcon: {
        height: '150px',
        width: '150px',
        color: '#d81c24'
    },
    bottomMsg: {
        marginTop: '130px',
        color: '#999797'
    }
});
class SubscriptionRequestLandingPage extends Component {
    render() {
        const { classes } = this.props;
        let msg;
        if (this.props.status === 'Approved') {
            msg = 'You have subscribed the user Successfully!!';
        } else {
            if (this.props.status === 'Rejected') {
                msg = 'Request processed successfully! Subscription Request Rejected';
            } else {
                msg = 'Request Expired or Invalid';
            }
        }
        return (
            <div>
                {this.props.status === 'Invalid' ? (
                    <CancelIcon className={classes.cancelIcon} />
                ) : (
                    <CheckCircleIcon className={classes.tickIcon} />
                )}
                <h1>{msg}</h1>
                <p className={classes.bottomMsg}>You can close this tab</p>
            </div>
        );
    }
}
export default withStyles(styles)(SubscriptionRequestLandingPage);
