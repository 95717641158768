import React from 'react';
import ChangePasswordIcon from '@material-ui/icons/Lock';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    buttons: {
        textAlign: 'left',
        display: 'flex',
        padding: '0.6em 2em 2em 0.8em',
        width: '100%',
        color: '#fff',
        margin: '0',
        fontSize: '18px',
        height: '1em',
        backgroundColor: 'rgba(0,0,0,0)',
        border: 'none',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none'
        },
        '&:hover': {
            backgroundColor: '#1a3f2a'
        }
    },
    userControlOptions: {
        '@media(min-width:2400px)': {
            margin: '5vh 0 0 0'
        }
    },
    changePassword: {
        margin: '1.2% 0 0 10%'
    },
    logout: {
        margin: '1% 0 0 10%'
    }
});

const UserOptions = props => {
    const { classes } = props;
    return (
        <div className={classes.userControlOptions}>
            <button onClick={props.toggleOpenChangePasswordModal} className={classes.buttons}>
                <ChangePasswordIcon />
                <p className={classes.changePassword}>Change Password</p>
            </button>
            <button onClick={props.logout} className={classes.buttons}>
                <img src="/static/images/logout-icon-18-256.png" alt="logout" height="25px" />
                <p className={classes.logout}>Logout</p>
            </button>
        </div>
    );
};

export default withStyles(styles)(UserOptions);
