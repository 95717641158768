import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import * as types from '../actions/ActionTypes';
import { connect } from 'react-redux';
import NotFound404Page from '../pages/NotFound404Page';
import ManagerHome from '../manager/ManagerHome';
import * as userActions from '../actions/UserActions';
import CourseSubscribe from '../courses/CourseSubscribe';
import ApproveCourseSubscription from '../pages/ApproveCourseSubscription';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
    />
);

function Routes(props) {
    useEffect(() => {
        const checkLogin = props.checkLogin;
        checkLogin();
    }, [props.loginState, props.loginLink, props.checkLogin]);

    if (!props.loginState) return null;
    const isAuthenticated = props.loginState === types.STATE_SUCCESS;
    return (
        <Router>
            <Switch>
                <Route
                    path="/login"
                    exact
                    render={() => {
                        window.location.assign(`http://${props.loginLink}?continue=${window.location.host}`);
                    }}
                />
                <PrivateRoute path="/" isAuthenticated={isAuthenticated} exact component={ManagerHome} />
                <PrivateRoute path="/organisations/:id" isAuthenticated={isAuthenticated} exact component={ManagerHome} />
                <PrivateRoute path="/user/:id" isAuthenticated={isAuthenticated} exact component={CourseSubscribe} />
                <Route path="/subscribe_course_request" exact component={ApproveCourseSubscription} />
                <Route exact component={NotFound404Page} />
            </Switch>
        </Router>
    );
}

const mapStateToProps = state => ({
    loginState: state.users.loginState,
    loginLink: state.links.login ? state.links.login[0].href : null
});

const mapDispatchToProps = dispatch => ({
    checkLogin: () => dispatch(userActions.checkLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
